(function () {
  'use strict';

  angular
    .module('neo.home.statistics.teams')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('home.statistics.teams', {
        url: '/teams',
        templateUrl: 'home/statistics/teams/teams.tpl.html',
        controller: 'StatsTeamsCtrl',
        controllerAs: 'vm',
        resolve: {
          teamStats: function (TeamStatistics, currentSeason) {
            return TeamStatistics.query({season_id: currentSeason.id}).$promise;
          }
        }
      });
  }
}());
